import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../utils";

const ItemTableCounter = ({ row, onUpdate }) => {

  return (
    <tr>
      <td>{row.name}</td>
      <td width={"20%"}>{row.measure_unit.description}</td>
      {/* <td>{row.control.description}</td> */}
      <td width={"30%"}>
        <input
          type={"number"}
          className="form-control input-sm"
          onBlur={(e) => onUpdate(row, e.target.value)}
        />
      </td>
    </tr>
  );
};

export default ItemTableCounter;
