import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getDetailOrderWorker } from '../../Redux/orderDuck';
import { Card, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import SubItemTableOrder from './subitem.table.order';


const OrderWorkerPayment = ({ getDetailOrderWorker, fetching_worker_detail, data_detail }) => {
    const search = useLocation().search;
    const cashier_id = new URLSearchParams(search).get("cashier_id");
    const date_init = new URLSearchParams(search).get("date_init");
    const date_end = new URLSearchParams(search).get("date_end");
    const status_id = new URLSearchParams(search).get("status_id");
    const operator_id = new URLSearchParams(search).get("operator_id");
    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_worker_detail === 2) {
            setList(data_detail.results)
        }
    }, [fetching_worker_detail])

    useEffect(() => {
        getDetailOrderWorker({ cashier_id, date_init, date_end, status_id, operator_id })
    }, [cashier_id, date_init, date_end])


    const onEditPercent = () => {
        console.log('edit percent')
    }

    return (
        <TemplateMenu>
            <Row>
                <Col>Pago a Jorge</Col>
            </Row>
            <Row>
                <Col lg={6}>
                    {list.map((a, k) => <Row key={k}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Turno ({a.id}) ({a.name})</Card.Title>
                                    <Row className='my-1'>
                                        <Col><b>Fecha Apertura</b></Col>
                                        <Col>{moment(a.date_start).format("YYYY-MM-DD")}</Col>
                                        <Col><b>Fecha Cierre</b></Col>
                                        <Col>{moment(a.date_end).format("YYYY-MM-DD")}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>

                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Orden</th>
                                                        <th>Vehículo</th>
                                                        <th>Servicio</th>
                                                        <th>Tipo</th>
                                                        <th>Valor</th>
                                                        <th>Prop</th>
                                                        <th>Precio</th>
                                                        <th>SubTotal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {a.orders.length > 0 ? a.orders.map((d, k) => <SubItemTableOrder key={k} item={d} onEditPercent={onEditPercent} />) : <tr>
                                                        <td colSpan={9} align='center'>No hay Datos</td></tr>}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={7}>SubTotal</th>
                                                        <td align='right'>
                                                            {/* <b>{formatNumber(item.subtotal_real)}</b> */}
                                                        </td>
                                                        {/* <td align='right'><b>{formatNumber(item.payment_worker_real)}</b></td> */}
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={8}>Total Descuentos</th>
                                                        {/* <td align='right'><b>{formatNumber(item.discount)}</b></td> */}
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={8}>Total Propinas</th>
                                                        {/* <td align='right'><b>{formatNumber(item.tip)}</b></td> */}
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={8}>TOTAL A PAGAR</th>
                                                        {/* <td align='right'><b>{formatNumber(item.total)}</b></td> */}
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col></Row>)}
                </Col>
            </Row>

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { order } = props;
    console.log('order', order);

    return {
        fetching_worker_detail: order.fetching_worker_detail,
        data_detail: order.data_detail,
    }
}

export default connect(mapsState, { getDetailOrderWorker })(OrderWorkerPayment);