import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Pages/Home';
import Categories from './Pages/Categories';
import Parameters from './Pages/Parameters';
import Login from './Pages/Login';
import PrivateRoute from './RoutesPrivate'
import Users from './Pages/Users';
import ClientPage from './Pages/Clients';
import EditClientPage from './Pages/Clients/edit.page';
import ProductsPage from './Pages/Products';
import ServicePage from './Pages/Services';
import WhatsAppPage from './Pages/Whatsapp';
import BusinessPage from './Pages/Business';
import CreateBusinessPage from './Pages/Business/create.business';
import BranchOfficePage from './Pages/BranchOffice';
import PermissionPage from './Pages/Permission';
import EditPermissionPage from './Pages/Permission/edit.permission';
import OrderPage from './Pages/Orders';
import EditOrderPage from './Pages/Orders/edit.order.page';
import OrderWorkerPage from './Pages/OrderWorkers';
import ExpensePage from './Pages/Expense';
import OrderDPFPage from './Pages/OrderWorkers/order.pdf';
import ReviewPage from './Pages/Review';
import InitialInventoryPage from './Pages/Inventory/initial.inventory';
import InventoryPage from './Pages/Inventory';
import SupplierPage from './Pages/Supplier';
import CreateProductPage from './Pages/Products/create.product';
import EditProductPage from './Pages/Products/edit.product';
import PurchasePage from './Pages/Purchase';
import CreatePurchasePage from './Pages/Purchase/create.purchase';
import EditPurchasePage from './Pages/Purchase/edit.purchase';
import low_downPage from './Pages/Inventory/low_down.page';
import ExpenseRecurrentPage from './Pages/ExpenseRecurrent';
import PublicityPage from './Pages/Publicity';
import CreatePublicityPage from './Pages/Publicity/create.publicity';
import InventoryCounterPage from './Pages/Inventory/InventoryCount';
import CreateInventoryCounterPage from './Pages/Inventory/InventoryCount/create.inventory.count';
import EditInventoryCounterPage from './Pages/Inventory/InventoryCount/edit.inventory.count';
import OrderDetailPdf from './Pages/Orders/order.detail.pdf';
import ProfilePage from './Pages/Users/profile.page';
import EndMonthPage from './Pages/EndMonth';
import SalesProductsPage from './Pages/SalesProducts';
import ParkingPage from './Pages/parking';
import ParkingConfigPage from './Pages/parking/index.config';
import EditParkingConfigPage from './Pages/parking/edit.config';
import OrderManualPage from './Pages/Orders/index.manual';
import CreateOrderPage from './Pages/Orders/create.order.page';
import TurnPage from './Pages/Turn';
import DetailingPage from './Pages/Detailing';
import CotizationPage from './Pages/Cotization';
import BrandPage from './Pages/Brand';
import BrandModel from './Pages/BrandModel';
import ReportByTurn from './Pages/Report/report_by_turn';
import ReportByClient from './Pages/Report/report_by_client';
import ProductsInventory from './Pages/ProductsInventory';
import CategoryProduct from './Pages/CategoryProduct';
import createProductInventory from './Pages/ProductsInventory/create.product.inventory';
import editProductInventory from './Pages/ProductsInventory/edit.product.inventory';
import PrescriptionPage from './Pages/Prescription';
import CreatePrescriptionPage from './Pages/Prescription/create.prescription';
import EditPrescriptionPage from './Pages/Prescription/edit.prescription';
import ReviewBankPage from './Pages/Review/review.bank';
import ReviewInsumoPage from './Pages/Review/review.insumos';
import ReviewCreditPage from './Pages/Review/review.credit';
import ReviewCxcPage from './Pages/Review/review.cxc';
import ReviewSaleServicesPage from './Pages/Review/review.sales.services';
import ReviewParkingPage from './Pages/Review/review.parking';
import AdvanceSuppliesPage from './Pages/AdvanceSupplies';
import ViewOrderClientPage from './Pages/Orders/view.order.client';
import WhatsappConfigPage from './Pages/Whatsapp/whatsapp.config.page';
import DeletaDataPage from './Pages/Admin/delete.data.page';
import EditBusinessPage from './Pages/Business/edit.business';
import RatingPage from './Pages/Report/rating_page';
import vehiclesPage from './Pages/Vehicle';
import ExistancePage from './Pages/Inventory/existence.products';
import ExistanceDetailProduct from './Pages/Inventory/existence.detail.products';
import BriefCasePage from './Pages/Briefcase';
import createBriefcase from './Pages/Briefcase/create.briefcase';
import EditBriefcasePage from './Pages/Briefcase/edit.briefcase';
import reportByVehicle from './Pages/Report/report_by_vehicle';
import PayrollPage from './Pages/Payroll';
import CreaatePayrollPage from './Pages/Payroll/create.payroll';
import ExpiredPlan from './Pages/Plan/expired.plan.page';
import OtherRevenuesPage from './Pages/OtherRevenues';
import orderWorkerPayment from './Pages/OrderWorkers/order.worker.payment';

const Routes = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/categorias" component={Categories} />
            <PrivateRoute exact path="/categoria-productos" component={CategoryProduct} />
            <Route exact path="/parametros" component={Parameters} />
            <PrivateRoute exact path="/usuarios" component={Users} />
            <PrivateRoute exact path="/clientes" component={ClientPage} />
            <PrivateRoute exact path="/clientes/:id" component={EditClientPage} />
            <PrivateRoute exact path="/business" component={BusinessPage} />
            <PrivateRoute exact path="/business-create" component={CreateBusinessPage} />
            <PrivateRoute exact path="/business/:id" component={EditBusinessPage} />
            <PrivateRoute exact path="/productos" component={ProductsPage} />
            <PrivateRoute exact path="/productos/new" component={CreateProductPage} />
            <PrivateRoute exact path="/productos/:id" component={EditProductPage} />

            <PrivateRoute exact path="/productos-inventory" component={ProductsInventory} />
            <PrivateRoute exact path="/productos-inventory/new" component={createProductInventory} />
            <PrivateRoute exact path="/productos-inventory/:id" component={editProductInventory} />

            <PrivateRoute exact path="/servicios" component={ServicePage} />
            <PrivateRoute exact path="/oficinas" component={BranchOfficePage} />
            <PrivateRoute exact path="/whatsapp" component={WhatsAppPage} />
            <PrivateRoute exact path="/whatsapp-config" component={WhatsappConfigPage} />
            <PrivateRoute exact path="/permisos" component={PermissionPage} />
            <PrivateRoute exact path="/permisos/:id" component={EditPermissionPage} />
            <PrivateRoute exact path="/ordenes-manuales" component={OrderManualPage} />
            <PrivateRoute exact path="/ordenes" component={OrderPage} />
            <PrivateRoute exact path="/ordenes/new" component={CreateOrderPage} />
            <PrivateRoute exact path="/ordenes/:id" component={EditOrderPage} />
            <PrivateRoute exact path="/ordenes/:id/pdf" component={OrderDetailPdf} />
            <Route exact path="/order-detail/:id" component={ViewOrderClientPage} />
            <PrivateRoute exact path="/ordenes-trabajadores" component={OrderWorkerPage} />
            <PrivateRoute exact path="/ordenes-trabajadores-pagos" component={orderWorkerPayment} />
            <PrivateRoute exact path="/ordenes-trabajadores/:id/:date_init/:date_end/:cashier_id" component={OrderDPFPage} />
            <PrivateRoute exact path="/gastos" component={ExpensePage} />
            <PrivateRoute exact path="/resumen" component={ReviewPage} />
            <PrivateRoute exact path="/resumen-banco/:method_payment_id" component={ReviewBankPage} />
            <PrivateRoute exact path="/resumen-insumo/:date_init/:date_end" component={ReviewInsumoPage} />
            <PrivateRoute exact path="/resumen-creditos/:date_init/:date_end" component={ReviewCreditPage} />
            <PrivateRoute exact path="/resumen-cxc/:date_init/:date_end" component={ReviewCxcPage} />
            <PrivateRoute exact path="/resumen-ventas-servicios/:date_init/:date_end" component={ReviewSaleServicesPage} />
            <PrivateRoute exact path="/resumen-parqueadero/:date_init/:date_end" component={ReviewParkingPage} />
            <PrivateRoute exact path="/inventario-inicial" component={InitialInventoryPage} />
            <PrivateRoute exact path="/proveedores" component={SupplierPage} />
            <PrivateRoute exact path="/compras" component={PurchasePage} />
            <PrivateRoute exact path="/compras/new" component={CreatePurchasePage} />
            <PrivateRoute exact path="/compras/:id" component={EditPurchasePage} />
            <PrivateRoute exact path="/inventario" component={InventoryPage} />
            <PrivateRoute exact path="/bajas" component={low_downPage} />
            <PrivateRoute exact path="/gastos-recurrentes" component={ExpenseRecurrentPage} />
            <PrivateRoute exact path="/publicidad" component={PublicityPage} />
            <PrivateRoute exact path="/publicidad/new" component={CreatePublicityPage} />
            <PrivateRoute exact path="/conteo-fisico" component={InventoryCounterPage} />
            <PrivateRoute exact path="/conteo-fisico/new" component={CreateInventoryCounterPage} />
            <PrivateRoute exact path="/conteo-fisico/:id" component={EditInventoryCounterPage} />
            <PrivateRoute exact path="/perfil" component={ProfilePage} />
            <PrivateRoute exact path="/cierre-de-mes" component={EndMonthPage} />
            <PrivateRoute exact path="/ventas-de-productos" component={SalesProductsPage} />
            <PrivateRoute exact path="/parqueadero" component={ParkingPage} />
            <PrivateRoute exact path="/tarifas-parqueadero" component={ParkingConfigPage} />
            <PrivateRoute exact path="/tarifas-parqueadero/:id" component={EditParkingConfigPage} />
            <PrivateRoute exact path="/turnos" component={TurnPage} />
            <PrivateRoute exact path="/valoracion-inventario" component={DetailingPage} />
            <PrivateRoute exact path="/cotizaciones" component={CotizationPage} />
            <PrivateRoute exact path="/marcas" component={BrandPage} />
            <PrivateRoute exact path="/marcas-model" component={BrandModel} />
            <PrivateRoute exact path="/entrega-turno" component={ReportByTurn} />
            <PrivateRoute exact path="/recetas" component={PrescriptionPage} />
            <PrivateRoute exact path="/recetas/new" component={CreatePrescriptionPage} />
            <PrivateRoute exact path="/recetas/:id" component={EditPrescriptionPage} />
            <PrivateRoute exact path="/cliente-frecuente" component={ReportByClient} />
            <PrivateRoute exact path="/vehiculo-frecuente" component={reportByVehicle} />
            <PrivateRoute exact path="/insumos-anticipos" component={AdvanceSuppliesPage} />
            <PrivateRoute exact path="/delete-data" component={DeletaDataPage} />
            <PrivateRoute exact path="/calificaciones" component={RatingPage} />
            <PrivateRoute exact path="/vehiculos" component={vehiclesPage} />
            <PrivateRoute exact path="/existencias" component={ExistancePage} />
            <PrivateRoute exact path="/existencias/:id" component={ExistanceDetailProduct} />
            <PrivateRoute exact path="/cartera" component={BriefCasePage} />
            <PrivateRoute exact path="/cartera/:id" component={EditBriefcasePage} />
            <PrivateRoute exact path="/cartera-create" component={createBriefcase} />
            <PrivateRoute exact path="/nomina" component={PayrollPage} />
            <PrivateRoute exact path="/nomina-create" component={CreaatePayrollPage} />
            <PrivateRoute exact path="/expired-plan" component={ExpiredPlan} />
            <PrivateRoute exact path="/otros-ingresos" component={OtherRevenuesPage} />

        </Switch>
    )
}

export default Routes;