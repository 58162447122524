import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { getCategoryProduct } from '../../Redux/categoryProductDuck'
import { getListSuppplier } from '../../Redux/supplierDuck'
import SelectField from '../Components/Widgets/SelectField';
import { MdAdd, MdAddCircle, MdAddCircleOutline } from 'react-icons/md';
import ModalCategory from './modal.category';
import chooseimage from "../../Images/no_available.png";
import ModalSupplier from '../Supplier/modal.supplier';
import ModalCategoryProduct from '../CategoryProduct/ModalCategoryProduct';

const FormProduct = ({ getParameterBySegment, data_segment, segment, errors, fetching_segment, getListSuppplier, fetching_list, data, formEdit, onSubmit,
    getCategoryProduct, fetching_list_category, data_category, category_id }) => {

    const [form, setForm] = useState({
        name: '', description: '', measure_unit_id: 14, tax_id: 15, category_id: 0, image: '', price: 0, cost: 0, priority: 1, status: true,
        has_inventory: false, bar_code: '', control_inventory_id: 18, supplier_id: 0, initial: 0, allow_without_stock: true
    })

    const [image, setImage] = useState('')
    const [listMeasure, setListMeasure] = useState([])
    const [listTax, setListTax] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [listGroup, setListGroup] = useState([])
    const [listControl, setListControl] = useState([])
    const [listSupplier, setListSupplier] = useState([])
    const [modalCategory, setModalCategory] = useState(false)
    const [modalSupplier, setModalSupplier] = useState(false)

    useEffect(() => {
        if (formEdit !== undefined && formEdit !== null) {
            setForm(formEdit)
            setImage(formEdit.image)
        }
    }, [formEdit])

    useEffect(() => {
        if (category_id !== undefined) {
            setForm({
                ...form,
                "category_id": category_id
            })
        }
    }, [category_id])

    useEffect(() => {
        getParameterBySegment("measure_unit");
        getCategoryProduct()
        getListSuppplier()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setListSupplier(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_list_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_list_category])

    useEffect(() => {
        if (fetching_segment === 2) {
            if (segment === 'measure_unit') {
                setListMeasure(data_segment)
                getParameterBySegment("tax");
            }
            if (segment === 'tax') {
                setListTax(data_segment)
                getParameterBySegment("control_inventory");
            }

            if (segment === 'group_inventory') {
                setListGroup(data_segment)
            }

            if (segment === 'control_inventory') {
                setListControl(data_segment)
            }

        }
    }, [fetching_segment])

    const updateImage = (e) => {
        setForm({ ...form, image: e.target.files[0] });


        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result)

            }
        };
        reader.readAsDataURL(file);
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmitPre = () => {
        onSubmit(form)
    }

    const onSuccessCategory = () => {
        setModalCategory(false)
        getCategoryProduct()
    }

    const onSuccessCreateSupplier = () => {
        setModalSupplier(false)
        getListSuppplier()
    }

    return (
        <Container fluid>
            <Row className='d-flex justify-content-end my-3'>
                <Col lg={2} ><button className='btn btn-sm btn-success' onClick={onSubmitPre}>Guardar</button></Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Nombre</label>
                                <InputField
                                    placeholder="Nombre"
                                    value={form.name || ''}
                                    name="name"
                                    onChange={updateInput}
                                    error={errors.name}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Descripción</label>
                                <InputField
                                    placeholder="Descripcion"
                                    value={form.description || ''}
                                    name="description"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectField name='measure_unit_id'
                                label={"Unidad de Medida"}
                                value={form.measure_unit_id}
                                onChange={updateInput}
                                data={listMeasure}
                                error={errors.measure_unit_id}
                                option={"description"}
                            />
                        </Col>
                        <Col>
                            <SelectField name='tax_id'
                                label={"Impuesto"}
                                value={form.tax_id}
                                onChange={updateInput}
                                data={listTax}
                                error={errors.tax_id}
                                option={"description"}
                            />
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Categoria <MdAddCircleOutline size={20} className="cursor-pointer" onClick={() => setModalCategory(true)} /> </label>
                                <select className="form-control" name="category_id" value={form.category_id} onChange={updateInput}>
                                    <option value="0">Seleccione</option>
                                    {listCategory.map((row, i) => <option key={i} value={row.id}>{row.name}</option>)}
                                </select>
                                {errors.category_id !== undefined ? (<label className="error-label">{errors.category_id}</label>) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Precio</label>
                                <InputField
                                    type="number"
                                    placeholder="price"
                                    value={form.price}
                                    name="price"
                                    onChange={updateInput}
                                    error={errors.price}
                                />
                            </div>
                        </Col>

                        <Col>
                            <div className="form-group">
                                <label>Orden</label>
                                <InputField
                                    type="number"
                                    placeholder="Orden"
                                    value={form.priority || ''}
                                    name="priority"
                                    onChange={updateInput}
                                    error={errors.priority}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Código de barras</label>
                                <InputField
                                    placeholder="Código de barras"
                                    value={form.bar_code}
                                    name="bar_code"
                                    onChange={updateInput}
                                    error={errors.bar_code}
                                />
                            </div>
                        </Col>


                    </Row>
                    <Row>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>¿Esta Activo?</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>¿Activar Inventario?</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.has_inventory}
                                    name="has_inventory"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                    {form.has_inventory &&
                        <Row className='my-3'>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Adición para Inventario</Card.Title>
                                        <Row>
                                            <Col>
                                                <SelectField name='control_inventory_id'
                                                    label={"Tipo de Control"}
                                                    value={form.control_inventory_id}
                                                    onChange={updateInput}
                                                    data={listControl}
                                                    error={errors.control_inventory_id}
                                                    option={"description"}
                                                />
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Proveedor <MdAddCircleOutline size={20} className="cursor-pointer" onClick={() => setModalSupplier(true)} /></label>
                                                    <select className="form-control" name="supplier_id" value={form.supplier_id} onChange={updateInput}>
                                                        <option value="0">Seleccione</option>
                                                        {listSupplier.map((row, i) => <option key={i} value={row.id}>{row.business}</option>)}
                                                    </select>
                                                    {errors.supplier_id !== undefined ? (<label className="error-label">{errors.supplier_id}</label>) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Costo</label>
                                                    <InputField
                                                        type="number"
                                                        placeholder="cost"
                                                        value={form.cost}
                                                        name="cost"
                                                        onChange={updateInput}
                                                        error={errors.cost}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <div className="form-group">
                                                    <label>Inventario Inicial</label>
                                                    <InputField
                                                        type="number"
                                                        placeholder="initial"
                                                        value={form.initial}
                                                        name="initial"
                                                        onChange={updateInput}
                                                        error={errors.cost}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="form-group">
                                                    <label>¿Permitir vender sin Stock?</label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-control"
                                                        checked={form.allow_without_stock}
                                                        name="allow_without_stock"
                                                        onChange={updateCheck}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>}

                </Col>
                <Col lg={4}>
                    <Row>
                        <Col>FOTO</Col>
                    </Row>
                    <Row>
                        <Col >
                            <div className="form-group">
                                <label htmlFor="chooseimage" className="uploadfile">
                                    {form.image !== "" ? (
                                        <img src={image} className="img-fluid" />
                                    ) : (
                                        <img src={chooseimage} className="img-fluid" />
                                    )}
                                </label>
                                <input
                                    type="file"
                                    name="photo"
                                    className="form-control"
                                    onChange={updateImage}
                                    id="chooseimage"
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalCategoryProduct show={modalCategory} onSuccess={onSuccessCategory} onHide={() => setModalCategory(false)} formEdit={{}} />
            <ModalSupplier show={modalSupplier} formEdit={{}} onSuccess={onSuccessCreateSupplier} onHide={() => setModalSupplier(false)} />
        </Container>
    )
}

const mapsState = (props) => {
    let { parameter, supplier, product, category_product } = props

    return {
        data_segment: parameter.data_segment,
        segment: parameter.segment,
        fetching_segment: parameter.fetching_segment,
        fetching_list: supplier.fetching_list,
        fetching_list_category: category_product.fetching_list,
        data_category: category_product.data,
        data: supplier.data,
        errors: product.errors,
    }
}

export default connect(mapsState, { getParameterBySegment, getListSuppplier, getCategoryProduct })(FormProduct);