import axios from 'axios'
import { getHeaders, getHeadersImage, removeSession, validateInput, validateProduct } from '../utils';
const API_URL = process.env.REACT_APP_API_URL
const API_URL_SOCKET = process.env.REACT_APP_SOCKET_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_edit: 0,
    fetching_by_id: 0,
    fetching_delete: 0,
    fetching_initial: 0,
    data: [],
    row: {},
    errors: {},
    msg: ''
}

let PRODUCT = "PRODUCT";
let PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
let PRODUCT_ERROR = "PRODUCT_ERROR";
let PRODUCT_LIST = "PRODUCT_LIST";
let PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
let PRODUCT_ID = "PRODUCT_ID"
let PRODUCT_ID_SUCCESS = "PRODUCT_ID_SUCCESS"
let INIT_PRODUCT = "INIT_PRODUCT"
let DELETE_PRODUCT = "DELETE_PRODUCT"
let DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
let DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR"

let PRODUCT_ALDELO = "PRODUCT_ALDELO"
let LOSE_SESSION = "LOSE_SESSION"
let LIST_PRODUCT_INITIAL = "LIST_PRODUCT_INITIAL"
let LIST_PRODUCT_INITIAL_SUCCESS = "LIST_PRODUCT_INITIAL_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case INIT_PRODUCT:
            return { ...state, fetching: 0, selected: {} }
        case PRODUCT:
            return { ...state, fetching_edit: 1 }
        case PRODUCT_SUCCESS:
            return { ...state, fetching_edit: 2, selected: action.payload, errors: {} }
        case PRODUCT_ERROR:
            return { ...state, fetching_edit: 3, errors: action.payload, selected: action.form }

        case PRODUCT_LIST:
            return { ...state, fetching_list: 1 }
        case PRODUCT_LIST_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_edit: 0 }
        case PRODUCT_ID:
            return { ...state, fetching_by_id: 1 }
        case PRODUCT_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }

        case DELETE_PRODUCT:
            return { ...state, fetching_delete: 1 }
        case DELETE_PRODUCT_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case DELETE_PRODUCT_ERROR:
            return { ...state, fetching_delete: 3, msg: action.payload }
        case LIST_PRODUCT_INITIAL:
            return { ...state, fetching_initial: 1 }
        case LIST_PRODUCT_INITIAL_SUCCESS:
            return { ...state, fetching_initial: 2, data: action.payload, fetching_edit: 0 }

        default:
            return state;
    }
}

export let initDuck = () => (dispatch, getState) => {
    dispatch({
        type: INIT_PRODUCT,
    })
}

export let createOrUpdateProduct = (form) => (dispatch, getState) => {

    dispatch({
        type: PRODUCT
    })


    let rules = [
        { field: "name", msg: "El nombre es requerido" },
        { field: "description", msg: "La descripción es requerida" },
        { field: "measure_unit_id", msg: "La Unidad de medida es requerida" },
        { field: "tax_id", msg: "El impuesto es requerido" },
        { field: "category_inventory_id", msg: "La Categoria es requerida" },
        { field: "priority", msg: "El Orden es requerido" },
        { field: "price", msg: "El Precio es requerido" },
    ]

    if (form.has_inventory === true) {
        rules.push({ field: "control_inventory_id", msg: "El tipo de control es requerido" },)
        rules.push({ field: "supplier_id", msg: "El Proveedor es requerido" },)
        rules.push({ field: "cost", msg: "El Costo es requerido" },)
    }


    let validation = validateInput(form, rules);

    if (validation !== null) {
        dispatch({
            type: PRODUCT_ERROR,
            payload: validation
        })
        return;
    }

    let formData = new FormData()


    for (const [key, value] of Object.entries(form)) {
        formData.append(key, value);
    }

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))


        if (form.id === undefined) {
            formData.append("branch_id", branch.id)
        }

        axios.post(`${API_URL}/api/products?date_init=${date_init}&date_end=${date_end}`, formData, {
            headers: getHeadersImage()
        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            let err = {}
            console.log('error.response.data.errors', error.response.data.errors);

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                console.log('value', value);

                if ('name' === key) {
                    err.name = value
                }
                if ('description' === key) {
                    err.description = value
                }
                if ('bar_code' === key) {
                    err.bar_code = value
                }
            }

            console.log('err', err);


            dispatch({
                type: PRODUCT_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let updateProduct = (form) => (dispatch, getState) => {

    dispatch({
        type: PRODUCT
    })

    let rules = [
        { field: "name", msg: "El nombre es requerido" },
        { field: "description", msg: "La descripción es requerida" },
        { field: "measure_unit_id", msg: "La Unidad de medida es requerida" },
        { field: "tax_id", msg: "El impuesto es requerido" },
        { field: "family_inventory_id", msg: "La familia es requerida" },
        { field: "group_inventory_id", msg: "La grupo es requerido" },
        { field: "control_inventory_id", msg: "La tipo de Control es requerido" },
        { field: "supplier_id", msg: "El proveedor es requerido" },
        { field: "priority", msg: "El Orden es requerido" },
    ]

    let validation = validateInput(form, rules);

    if (validation !== null) {
        dispatch({
            type: PRODUCT_ERROR,
            payload: validation
        })
        return;
    }


    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/products/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
                if ('priority' === key) {
                    err.priority = value[0]
                }
                if ('price' === key) {
                    err.price = value[0]
                }

            }

            dispatch({
                type: PRODUCT_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let uploadData = (list) => (dispatch, getState) => {

    dispatch({
        type: PRODUCT
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.post(`${API_URL}/api/products-manual`, { products: list, branch_id: branch.id }, {
            headers: getHeaders()
        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}



export let getProductById = (id) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_ID,
        payload: []
    })

    if (getState().user.loggedIn) {
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        axios.get(`${API_URL}/api/products/${id}?date_init=${date_init}&date_end=${date_end}`, {
            headers: getHeaders()
        }).then(resp => {

            dispatch({
                type: PRODUCT_ID_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                // dispatch({
                //     type: LOSE_SESSION
                // });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}


export let getProducts = () => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_LIST
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        axios.get(`${API_URL}/api/products?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getProductsAdmin = () => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_LIST
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        axios.get(`${API_URL}/api/products-admin?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getProductSales = () => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_LIST
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        axios.get(`${API_URL}/api/products-sales?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}



export let deleteProduct = (product) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PRODUCT
    })

    let url = `${API_URL}/api/products/${product.id}`;

    console.log('url', url);



    if (getState().user.loggedIn) {
        axios.delete(url, {
            headers: getHeaders()
        }).then(resp => {
            if (resp.data.ok === true) {
                dispatch({
                    type: DELETE_PRODUCT_SUCCESS,
                    payload: resp.data
                });
            } else {
                dispatch({
                    type: DELETE_PRODUCT_ERROR,
                    payload: resp.data.msg
                });
            }


        }).catch(err => {
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getProductsInitial = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PRODUCT_INITIAL
    })

    if (getState().user.loggedIn) {

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/products-initial?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_PRODUCT_INITIAL_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}
