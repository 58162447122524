import React, { useState } from 'react'
import { formatNumber } from '../../utils';
import { MdAddCircle, MdOutlineAddCircle, MdClose } from "react-icons/md";
import { Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SubItemTableOrder from './subitem.table.order';

const ItemTableOrderWorker = ({ item, onPaidEvent, form, onEditPercent, onOpenModalExpense, onDeleteRow, onChangePercent, turn_id }) => {

    let { expenses, orders } = item
    const { detail } = orders || { detail: [], days_worked: 0 }
    const [show, setShow] = useState(false);

    const onShowDetail = () => {
        setShow(!show)
    }

    return (
        <>
            <tr>
                <td>{show === false ?
                    <MdOutlineAddCircle className='cursor-pointer' onClick={onShowDetail} />
                    : <MdClose className='cursor-pointer' onClick={onShowDetail} />}
                </td>
                <td>{item.name}</td>
                <td align='right'>{orders !== null ? orders.days_worked : 0}</td>
                <td align='right'>{orders !== null ? orders.quantity : 0}</td>
                <td align='right'>{orders !== null ? formatNumber(orders.subtotal) : 0}</td>
                <td align='right'>{orders !== null ? formatNumber(orders.payment_worker) : 0}</td>
                <td align='right'>{expenses !== null ? formatNumber(expenses.reduce((a, b) => a + parseFloat(b.price), 0)) : 0}</td>
                <td align='right'>{orders !== null ? formatNumber(orders.tip) : 0}</td>
                <td align='right'>{orders !== null ? formatNumber(orders.total) : 0}</td>
                <td>
                    {parseInt(form.status_id) === 3 && <>
                        <button className='btn btn-sm btn-info ' onClick={() => onPaidEvent(item)}>Pagar</button>
                        <Link className='btn btn-sm btn-warning mx-1 '
                            target="_blank"
                            to={`/ordenes-trabajadores-pagos?cashier_id=${form.turn_id}&date_init=${form.date_init}&date_end=${form.date_end}&operator_id=${item.id}&status_id=${form.status_id}`}>Pagar</Link>
                        <button className='btn btn-sm btn-warning mx-1' onClick={() => onChangePercent(item)}>Cambio de %</button>

                    </>}
                    <a className='btn btn-sm btn-success'
                        target="_blank"
                        href={`${process.env.REACT_APP_API_URL}/api/orders-operator-view/${item.id}/${form.date_init}/${form.date_end}/${turn_id}?status_id=${form.status_id}`}>
                        PDF</a>
                </td>
            </tr>
            {show && <tr>
                <td colSpan={4}>
                    <Row>
                        <Col className='text-center'><h5>Servicios Realizados</h5></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table>

                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Orden</th>
                                        <th>Vehículo</th>
                                        <th>Servicio</th>
                                        <th>Tipo</th>
                                        <th>Valor</th>
                                        <th>Prop</th>
                                        <th>Precio</th>
                                        <th>SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail.length > 0 ? detail.map((d, k) => <SubItemTableOrder key={k} item={d} onEditPercent={onEditPercent} />) : <tr><td>No tienes Servicios</td></tr>}
                                </tbody>
                                <tfoot>
                                    {/* <tr>
                                        <th colSpan={7}>SubTotal</th>
                                        <td align='right'>
                                            <b>{formatNumber(item.subtotal_real)}</b>
                                        </td>
                                        <td align='right'><b>{formatNumber(item.payment_worker_real)}</b></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>Total Descuentos</th>
                                        <td align='right'><b>{formatNumber(item.discount)}</b></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>Total Propinas</th>
                                        <td align='right'><b>{formatNumber(item.tip)}</b></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>TOTAL A PAGAR</th>
                                        <td align='right'><b>{formatNumber(item.total)}</b></td>
                                    </tr> */}
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </td>
                <td colSpan={3}>
                    <Row>
                        <Col className='text-center'><h5>Anticipos<MdAddCircle className='cursor-pointer' onClick={() => onOpenModalExpense(item, 1)} /></h5> </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.filter(d => d.type_move_id === 1).length > 0 ? expenses.filter(d => d.type_move_id === 1).map((d, k) => <tr key={k}>
                                        <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                        <td >{d.description}</td>
                                        <td align='right'>{formatNumber(d.price)}</td>
                                        <td align='right'><MdClose className='cursor-pointer' onClick={() => onDeleteRow(d)} /></td>
                                    </tr>) : <tr><td colSpan={2}>No hay datos</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <td align='right'><b>{formatNumber(expenses.filter(d => d.type_move_id === 1).reduce((a, b) => a + parseFloat(b.price), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </td>

                <td colSpan={3}>
                    <Row>
                        <Col className='text-center'><h5>Insumos<MdAddCircle className='cursor-pointer' onClick={() => onOpenModalExpense(item, 2)} /></h5> </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.filter(d => d.type_move_id === 2).length > 0 ? expenses.filter(d => d.type_move_id === 2).map((d, k) => <tr key={k}>
                                        <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                        <td >{d.description}</td>
                                        <td align='right'>{formatNumber(d.price)}</td>
                                        <td align='right'><MdClose className='cursor-pointer' onClick={() => onDeleteRow(d)} /></td>
                                    </tr>) : <tr><td colSpan={2}>No hay datos</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <td align='right'><b>{formatNumber(expenses.filter(d => d.type_move_id === 2).reduce((a, b) => a + parseFloat(b.price), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </td>


            </tr>}
        </>
    )
}

export default ItemTableOrderWorker;